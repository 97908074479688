var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"subscription-dialog",attrs:{"title":_vm.username + '的订阅信息',"visible":_vm.dialogTableVisible,"center":"","close-on-press-escape":false,"close-on-click-modal":false,"width":"1020px"},on:{"update:visible":function($event){_vm.dialogTableVisible=$event}}},[_c('div',{staticClass:"table_div"},[_c('el-table',{staticStyle:{"width":"961px"},attrs:{"border":"","stripe":"","data":_vm.boxList,"row-key":"id","cell-style":{
                textAlign: 'center',
                alignItems: 'baseline',
                padding: '10px 0',
            },"header-cell-style":{
                background: '#eef1f6',
                color: '#606266',
                textAlign: 'center',
                padding: '12px 0',
            },"tree-props":{ children: 'children' }}},[_c('el-table-column',{attrs:{"prop":"index","label":"序号","width":"100"}}),_c('el-table-column',{attrs:{"prop":"blno","label":"提单号","width":"200"}}),_c('el-table-column',{attrs:{"prop":"vesselsName","label":"船名","width":"180"}}),_c('el-table-column',{attrs:{"prop":"voyage","label":"航次","width":"120"}}),_c('el-table-column',{attrs:{"prop":"remark","label":"备注","width":"180"}}),_c('el-table-column',{attrs:{"prop":"createTime","label":"订阅时间","width":"180"}}),_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('el-empty',{attrs:{"image-size":200}})],1)],1)],1),_c('div',{staticClass:"pagination_div"},[_c('el-pagination',{attrs:{"background":"","hide-on-single-page":_vm.pageTotal < 10,"layout":"sizes,prev, pager, next","total":_vm.pageTotal,"page-sizes":[10, 20, 30, 40, 50],"page-size":_vm.pageSize,"current-page":_vm.pagePosition},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.pagePosition=$event},"update:current-page":function($event){_vm.pagePosition=$event}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }