<template>
    <div style="width: 100%" v-if="this.loginId != 'personal'">
        <div class="background_class">
            <div class="message">
                <span class="title">同事管理</span>
                <div class="body">
                    <div class="menu">
                        <el-menu
                            :default-active="activeIndex"
                            class="el-menu-demo"
                            mode="horizontal"
                            @select="handleSelect"
                            text-color="#000"
                            active-text-color="#409EFF"
                        >
                            <el-menu-item index="0" style="font-size: 16px">
                                全部同事
                            </el-menu-item>
                            <el-menu-item
                                index="1"
                                style="font-size: 16px"
                                v-if="companyRole == 'Management'"
                            >
                                申请加入
                            </el-menu-item>
                            <el-badge
                                v-if="applyNum > 0"
                                :value="applyNum"
                                class="item"
                            ></el-badge>
                        </el-menu>
                    </div>
                    <div
                        :style="[
                            { minWidth: '1000px', width: '100%' },
                            activeIndex == '1' ? { display: 'none' } : {},
                        ]"
                    >
                        <div
                            class="button-input"
                            :style="[
                                companyRole == 'Management'
                                    ? { 'justify-content': 'space-between' }
                                    : { 'justify-content': 'flex-end' },
                            ]"
                        >
                            <div v-if="companyRole == 'Management'">
                                <!-- <el-button
                                    type="primary"
                                    @click="showAddDialog"
                                >
                                    添加员工
                                </el-button> -->
                                <el-button
                                    type="button"
                                    @click="showTureDialog"
                                >
                                    移交管理员
                                </el-button>
                            </div>
                            <!-- <div>
                                <el-input
                                    v-model="peopleSearch"
                                    style="width: 200px"
                                    placeholder="通过手机号搜索"
                                    :clearable="true"
                                    @clear="searchUser"
                                ></el-input>
                                <el-button type="primary" @click="searchUser">
                                    查询
                                </el-button>
                            </div> -->
                        </div>
                        <el-table
                            border
                            stripe
                            :data="colleagueDate"
                            style="width: 100%"
                            :default-sort="{
                                prop: 'date',
                                order: 'descending',
                            }"
                            :header-cell-style="{
                                color: '#606266',
                                textAlign: 'center',
                                padding: '12px 0',
                            }"
                            :cell-style="{
                                color: '#606266',
                                textAlign: 'center',
                                padding: '12px 0',
                            }"
                            height="56vh"
                        >
                            <template v-for="item in colleagueInform">
                                <el-table-column
                                    show-overflow-tooltip
                                    :prop="item.prop"
                                    :label="item.label"
                                    :key="item.prop"
                                    :resizable="item.resizable"
                                >
                                    <template slot-scope="scope">
                                        <span v-if="item.prop === 'operate'">
                                            <div
                                                style="
                                                    display: flex;
                                                    justify-content: space-evenly;
                                                "
                                                v-if="
                                                    companyRole == 'Management'
                                                "
                                            >
                                                <span
                                                    class="viewSubscription"
                                                    @click="
                                                        showCollegueBoxDialog(
                                                            scope.row.userName,
                                                            scope.row.userid,
                                                        )
                                                    "
                                                >
                                                    查看订阅
                                                </span>
                                                <span
                                                    class="deleteCollague"
                                                    v-if="
                                                        scope.row[
                                                            'companyRole'
                                                        ] != '管理员'
                                                    "
                                                    @click="
                                                        isDelete(
                                                            scope.row['id'],
                                                            scope.row[
                                                                'userName'
                                                            ],
                                                            'staff',
                                                        )
                                                    "
                                                >
                                                    删除
                                                </span>
                                            </div>
                                            <div
                                                style="
                                                    display: flex;
                                                    justify-content: space-evenly;
                                                "
                                                v-if="companyRole == 'staff'"
                                            >
                                                <span
                                                    class="viewSubscription"
                                                    v-if="
                                                        scope.row['id'] ==
                                                        $store.state.id
                                                    "
                                                    @click="
                                                        showCollegueBoxDialog(
                                                            scope.row.userName,
                                                            scope.row.userid,
                                                        )
                                                    "
                                                >
                                                    查看订阅
                                                </span>
                                                <span
                                                    class="deleteCollague"
                                                    v-if="
                                                        scope.row['id'] ==
                                                        $store.state.id
                                                    "
                                                    @click="
                                                        isQuit(
                                                            scope.row['id'],
                                                            'staff',
                                                        )
                                                    "
                                                >
                                                    退出企业
                                                </span>
                                            </div>
                                        </span>
                                        <span v-else>
                                            {{ scope.row[item.prop] }}
                                        </span>
                                    </template>
                                </el-table-column>
                            </template>
                            <div slot="empty">
                                <el-empty :image-size="200"></el-empty>
                            </div>
                        </el-table>
                    </div>
                    <div
                        :style="[activeIndex == '0' ? { display: 'none' } : {}]"
                        class="apply-inform"
                    >
                        <el-table
                            border
                            stripe
                            :data="applyDate"
                            style="width: 100%"
                            :default-sort="{
                                prop: 'date',
                                order: 'descending',
                            }"
                            :header-cell-style="{
                                color: '#606266',
                                textAlign: 'center',
                                padding: '12px 0',
                            }"
                            :cell-style="{
                                color: '#606266',
                                textAlign: 'center',
                                padding: '12px 0',
                            }"
                            height="56vh"
                        >
                            <template v-for="item in ApplyInform">
                                <el-table-column
                                    show-overflow-tooltip
                                    :prop="item.prop"
                                    :label="item.label"
                                    :width="item.width"
                                    :key="item.prop"
                                    :resizable="item.resizable"
                                >
                                    <template slot-scope="scope">
                                        <span v-if="item.prop === 'operate'">
                                            <div
                                                style="
                                                    display: flex;
                                                    justify-content: space-evenly;
                                                "
                                            >
                                                <span
                                                    class="apply-pass"
                                                    @click="
                                                        handlePass(
                                                            scope.row[
                                                                'applyUserId'
                                                            ],
                                                        )
                                                    "
                                                >
                                                    通过
                                                </span>
                                                <span
                                                    class="apply-refuse"
                                                    @click="
                                                        handleRefuse(
                                                            scope.row[
                                                                'applyUserId'
                                                            ],
                                                        )
                                                    "
                                                >
                                                    驳回
                                                </span>
                                            </div>
                                        </span>
                                        <span v-else>
                                            {{ scope.row[item.prop] }}
                                        </span>
                                    </template>
                                </el-table-column>
                            </template>
                            <div slot="empty">
                                <el-empty :image-size="200"></el-empty>
                            </div>
                        </el-table>
                    </div>
                </div>
                <div class="pagination_div">
                    <el-pagination
                        background
                        :hide-on-single-page="pageTotal < 10"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        layout="sizes,prev, pager, next"
                        :total="pageTotal"
                        :page-sizes="[10, 20, 30, 40, 50]"
                        :page-size="pageSize"
                        :current-page.sync="pagePosition"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <!-- <inviteNew-dialog
            v-if="addDialogVisible"
            ref="inviteNewDialog"
        ></inviteNew-dialog> -->
        <turnManage-dialog
            v-if="tureDialogVisible"
            ref="turnManageDialog"
            @isTurnOver="init"
        ></turnManage-dialog>
        <colleagueBox-dialog
            v-if="watchDialogVisible"
            ref="colleagueBoxDialog"
        ></colleagueBox-dialog>
    </div>
</template>

<script>
import inviteNewDialog from "../common/InviteNewDialog";
import turnManageDialog from "./components/TurnManageDialog";
import colleagueBoxDialog from "./components/ColleagueBoxDialog";
import {
    companyAddStaff,
    companyDelStaff,
    getCompanyStaffById,
    getStaffApplyList,
    staffApplyJoin,
} from "@/api";
import { searchByPhone } from "@/api/login";
import { apiCallback } from "@/utils";
import { max } from "moment";
export default {
    data() {
        return {
            activeIndex: "0",
            loginId: "", //登录身份
            addDialogVisible: false, //添加员工弹窗
            tureDialogVisible: false, //移交管理员弹窗
            watchDialogVisible: false, //查看订阅弹窗
            peopleSearch: "", //员工搜索
            companyId: 0, //公司编号
            pageTotal: 0,
            pageSize: 10,
            pagePosition: 1,
            companyRole: "", //当前公司身份
            colleagueInform: [
                {
                    prop: "companyRole",
                    label: "企业角色",
                    resizable: true,
                },
                {
                    prop: "userName",
                    label: "用户名",
                    resizable: true,
                },
                {
                    prop: "userTel",
                    label: "手机号",
                    resizable: true,
                },
                {
                    prop: "qqNumber",
                    label: "QQ号",
                    resizable: true,
                },
                {
                    prop: "userEmail",
                    label: "邮箱",
                    resizable: true,
                },
                {
                    prop: "operate",
                    label: "操作",
                    resizable: true,
                },
            ],
            ApplyInform: [
                {
                    prop: "userName",
                    label: "用户名",
                    resizable: true,
                    width: "260",
                },
                {
                    prop: "userTel",
                    label: "手机号",
                    resizable: true,
                    width: "260",
                },
                {
                    prop: "userEmail",
                    label: "邮箱",
                    resizable: true,
                    width: "320",
                },
                {
                    prop: "operate",
                    label: "操作",
                    resizable: true,
                    width: "159",
                },
            ],
            colleagueDate: [], //企业员工信息
            applyDate: [], //申请人信息
            applyNum: 0, //申请人个数
        };
    },
    components: {
        inviteNewDialog,
        turnManageDialog,
        colleagueBoxDialog,
    },
    mounted() {
        window.sessionStorage.setItem("activePath", "/user/manage-colleague");
        this.init();
    },
    methods: {
        async loadData () { 
            if (this.loginId != "personal") {
                await this.getUserInform();
                if (this.companyRole == "Management") {
                    await this.getApplyInform();
                }
            }
        },
        async init() {
            this.activeIndex = "0";
            this.loginId = this.$store.state.user.loginId;
            this.companyRole = "";
            this.colleagueDate = [];
            this.applyDate = [];
            this.applyNum = 0;
            this.loadData();
        },
        handleSelect(key, keyPath) {
            this.activeIndex = key;
            this.pageSize = 10;
            this.pagePosition = 1;
            if (key == 0) {
                this.getUserInform();
            }
            if (key == 1) {
                this.getApplyInform();
            }
        },
        handlePass(applyId) {
            this.$confirm("同意该同事加入并使用您企业下的资产和服务", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.applyPass(applyId);
                })
                .catch(() => {
                    //点击取消执行
                    this.$message({
                        type: "info",
                        message: "已取消",
                    });
                });
        },
        handleRefuse(applyId) {
            this.$confirm("是否驳回该加入申请", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.applyRefuse(applyId);
                })
                .catch(() => {
                    //点击取消执行
                    this.$message({
                        type: "info",
                        message: "已取消",
                    });
                });
        },
        showAddDialog() {
            this.addDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.inviteNewDialog.init(this.companyId);
            });
        },
        showTureDialog() {
            this.tureDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.turnManageDialog.init(
                    this.colleagueDate,
                    this.companyId,
                );
            });
        },
        showCollegueBoxDialog(username, userid) {
            this.watchDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.colleagueBoxDialog.init(username, userid);
            });
        },
        //每页个数修改
        handleSizeChange(val) {
            this.pageSize = val;
            this.pagePosition = 1;
            this.loadData();
        },
        //当前页修改
        handleCurrentChange(val) {
            this.pagePosition = val;
            this.loadData();
        },
        //员工信息
        async getUserInform() {
            var that = this;
            getCompanyStaffById({
                cname: this.loginId,
                pagenum: this.pagePosition,
                pagesize: this.pageSize,
            })
                .then((res) => {
                    let company = [];
                    const data = apiCallback(res);
                    that.pageTotal = data.total;
                    company = [...data.list];
                    that.dealUserInform(company);
                })
                .catch((err) => console.error(err));
        },
        //申请信息
        async getApplyInform() {
            var that = this;
            var data = {};
            data.cid = this.companyId;
            data.isaccess = 0;
            data.pagenum = this.pagePosition;
            data.pagesize = this.pageSize;
            getStaffApplyList(data)
                .then((res) => {
                    const data = apiCallback(res);
                    that.applyDate = [];
                    that.pageTotal = data.total;
                    that.applyNum = data.total;
                    data.list.forEach((user) => {
                        var applyUser = {};
                        applyUser.userName = user.uname;
                        applyUser.userTel = user.phone;
                        applyUser.applyUserId = user.uid;
                        that.applyDate.push(applyUser);
                    });
                })
                .catch((err) => console.error(err));
        },
        dealUserInform(list) {
            if (!list || !list.length) {
                return;
            }
            this.colleagueDate = [];
            this.companyId = list[0].cid;
            list.forEach((item) => {
                if (item.uid == this.$store.state.user.userId) {
                    this.companyRole = item.role; //获取当前公司身份
                }
                var user = {};
                user.id = item.uid;
                user.userid = item.userid;
                user.companyRole =
                    item.role == "Management" ? "管理员" : "普通员工";
                user.userName = item.uname;
                user.userTel = item.phone;
                user.qqNumber = item.qqNumber;
                user.userEmail = item.email;
                this.colleagueDate.push(user);
            });
        },
        dealSearchInform(obj) {
            // console.log(obj)
            this.colleagueDate = [];
            if (Object.keys(obj).length > 0) {
                obj.Users.forEach((item) => {
                    if (item.Id == this.$store.state.user.userId) {
                        this.companyRole = item.Role; //获取当前公司身份
                    }
                    var user = {};
                    user.id = item.Id;
                    user.userid = item.Userid;
                    user.companyRole =
                        item.Role == "Management" ? "管理员" : "普通员工";
                    user.userName = item.Name;
                    user.userTel = item.Phone;
                    user.userEmail = "";
                    this.colleagueDate.push(user);
                });
            }
        },
        dealSearchInform2(obj) {
            this.colleagueDate = [];
            if (obj.length > 0) {
                obj.forEach((item) => {
                    var user = {};
                    user.id = item.uid;
                    user.companyRole =
                        item.role == "Management" ? "管理员" : "普通员工";
                    user.userName = item.uname;
                    user.userTel = item.phone;
                    user.userEmail = item.email;
                    user.qqNumber = item.qqNumber;
                    this.colleagueDate.push(user);
                });
            }
        },
        //通过审核
        applyPass(applyId) {
            // console.log(this.companyId,this.$store.state.user.userId)
            if (!this.companyId || !applyId) {
                this.$message.error("error");
                return;
            }
            var that = this;
            var data = {};
            data.uid = applyId;
            data.cid = this.companyId;
            data.isaccess = 1;
            // data.role = 'staff'
            companyAddStaff(data)
                .then(async (res) => {
                    that.$message.success("已通过申请");
                    that.getApplyInform();
                })
                .catch((err) => console.error(err));
        },
        //驳回申请
        applyRefuse(applyId) {
            // console.log(this.companyId,this.$store.state.user.userId)
            if (!this.companyId || !applyId) {
                this.$message.error("error");
                return;
            }
            var that = this;
            var data = {};
            data.uid = applyId;
            data.cid = this.companyId;
            data.isaccess = 2;
            staffApplyJoin(data)
                .then((res) => {
                    that.$message.success("已驳回申请");
                    that.getApplyInform();
                })
                .catch((err) => console.error(err));
        },
        //根据电话查询
        searchUser() {
            if (this.peopleSearch) {
                var that = this;
                searchByPhone({
                    cid: this.companyId,
                    phone: this.peopleSearch,
                })
                    .then(async (res) => {
                        const data = apiCallback(res);
                        that.pageTotal = data.total;
                        that.dealSearchInform2(data);
                    })
                    .catch((err) => console.error(err));
            } else {
                this.getUserInform();
            }
        },
        //删除员工
        isDelete(uid, name, role) {
            this.$confirm("是否删除：" + name, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.quitCompany(uid, role, 0);
                })
                .catch(() => {
                    //点击取消执行
                    this.$message({
                        type: "info",
                        message: "已取消",
                    });
                });
        },
        //退出企业
        isQuit(uid, role) {
            this.$confirm("是否退出该企业", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.quitCompany(uid, role, 1);
                })
                .catch(() => {
                    //点击取消执行
                    this.$message({
                        type: "info",
                        message: "已取消",
                    });
                });
        },
        async quitCompany(uid, role, status) {
            var that = this;
            var data = {};
            data.uid = uid;
            data.cid = this.companyId;
            data.role = role;
            companyDelStaff(data)
                .then(async (res) => {
                    that.$message.success("删除成功");
                    that.getUserInform();
                })
                .catch((err) => console.error(err));
        },
    },
};
</script>

<style lang="stylus" scoped>
/deep/input::-webkit-input-placeholder {
  color: #778899;
}

.background_class {
  display: flex;
  background-color: #ffffff;
  height: 800px;
  width: auto;
  justify-content: flex-start;
}

.message {
  /* margin: 30px 0 8px 15px; */
  display: flex;
  flex-direction: column;
  width: 100%;
  /* font-size: 16px; */
  /* justify-content: center; */
}

.title {
    font-size: 1.68rem;
    font-weight: 700;
  padding: 1.68rem;
  background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
    color:white;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 20px;
}

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 12px;
  color: #989898;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-bottom-color: #e5e5e5;
  margin-bottom: 10px;
}

.el-menu-demo {
  display: flex;
  align-items: baseline;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px;
}

.item {
  transform: translateX(-18px) translateY(-10px);
}

.button-input {
  padding: 20px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
}

.apply-inform {
  padding: 20px 0px;
  width:100%;
  min-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewSubscription {
  cursor: pointer;
  color: rgb(85, 114, 253);
  user-select: none;
}

.deleteCollague {
  cursor: pointer;
  color: #ff3c3c;
  user-select: none;
}

.apply-pass {
  cursor: pointer;
  color: #409EFF;
  user-select: none;
}

.apply-refuse {
  color: #F56C6C;
  cursor: pointer;
  user-select: none;
}

.pagination_div {
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
