var render = function render(){var _vm=this,_c=_vm._self._c;return (this.loginId != 'personal')?_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"background_class"},[_c('div',{staticClass:"message"},[_c('span',{staticClass:"title"},[_vm._v("同事管理")]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"menu"},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.activeIndex,"mode":"horizontal","text-color":"#000","active-text-color":"#409EFF"},on:{"select":_vm.handleSelect}},[_c('el-menu-item',{staticStyle:{"font-size":"16px"},attrs:{"index":"0"}},[_vm._v(" 全部同事 ")]),(_vm.companyRole == 'Management')?_c('el-menu-item',{staticStyle:{"font-size":"16px"},attrs:{"index":"1"}},[_vm._v(" 申请加入 ")]):_vm._e(),(_vm.applyNum > 0)?_c('el-badge',{staticClass:"item",attrs:{"value":_vm.applyNum}}):_vm._e()],1)],1),_c('div',{style:([
                        { minWidth: '1000px', width: '100%' },
                        _vm.activeIndex == '1' ? { display: 'none' } : {},
                    ])},[_c('div',{staticClass:"button-input",style:([
                            _vm.companyRole == 'Management'
                                ? { 'justify-content': 'space-between' }
                                : { 'justify-content': 'flex-end' },
                        ])},[(_vm.companyRole == 'Management')?_c('div',[_c('el-button',{attrs:{"type":"button"},on:{"click":_vm.showTureDialog}},[_vm._v(" 移交管理员 ")])],1):_vm._e()]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","stripe":"","data":_vm.colleagueDate,"default-sort":{
                            prop: 'date',
                            order: 'descending',
                        },"header-cell-style":{
                            color: '#606266',
                            textAlign: 'center',
                            padding: '12px 0',
                        },"cell-style":{
                            color: '#606266',
                            textAlign: 'center',
                            padding: '12px 0',
                        },"height":"56vh"}},[_vm._l((_vm.colleagueInform),function(item){return [_c('el-table-column',{key:item.prop,attrs:{"show-overflow-tooltip":"","prop":item.prop,"label":item.label,"resizable":item.resizable},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(item.prop === 'operate')?_c('span',[(
                                                _vm.companyRole == 'Management'
                                            )?_c('div',{staticStyle:{"display":"flex","justify-content":"space-evenly"}},[_c('span',{staticClass:"viewSubscription",on:{"click":function($event){return _vm.showCollegueBoxDialog(
                                                        scope.row.userName,
                                                        scope.row.userid,
                                                    )}}},[_vm._v(" 查看订阅 ")]),(
                                                    scope.row[
                                                        'companyRole'
                                                    ] != '管理员'
                                                )?_c('span',{staticClass:"deleteCollague",on:{"click":function($event){return _vm.isDelete(
                                                        scope.row['id'],
                                                        scope.row[
                                                            'userName'
                                                        ],
                                                        'staff',
                                                    )}}},[_vm._v(" 删除 ")]):_vm._e()]):_vm._e(),(_vm.companyRole == 'staff')?_c('div',{staticStyle:{"display":"flex","justify-content":"space-evenly"}},[(
                                                    scope.row['id'] ==
                                                    _vm.$store.state.id
                                                )?_c('span',{staticClass:"viewSubscription",on:{"click":function($event){return _vm.showCollegueBoxDialog(
                                                        scope.row.userName,
                                                        scope.row.userid,
                                                    )}}},[_vm._v(" 查看订阅 ")]):_vm._e(),(
                                                    scope.row['id'] ==
                                                    _vm.$store.state.id
                                                )?_c('span',{staticClass:"deleteCollague",on:{"click":function($event){return _vm.isQuit(
                                                        scope.row['id'],
                                                        'staff',
                                                    )}}},[_vm._v(" 退出企业 ")]):_vm._e()]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(scope.row[item.prop])+" ")])]}}],null,true)})]}),_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('el-empty',{attrs:{"image-size":200}})],1)],2)],1),_c('div',{staticClass:"apply-inform",style:([_vm.activeIndex == '0' ? { display: 'none' } : {}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","stripe":"","data":_vm.applyDate,"default-sort":{
                            prop: 'date',
                            order: 'descending',
                        },"header-cell-style":{
                            color: '#606266',
                            textAlign: 'center',
                            padding: '12px 0',
                        },"cell-style":{
                            color: '#606266',
                            textAlign: 'center',
                            padding: '12px 0',
                        },"height":"56vh"}},[_vm._l((_vm.ApplyInform),function(item){return [_c('el-table-column',{key:item.prop,attrs:{"show-overflow-tooltip":"","prop":item.prop,"label":item.label,"width":item.width,"resizable":item.resizable},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(item.prop === 'operate')?_c('span',[_c('div',{staticStyle:{"display":"flex","justify-content":"space-evenly"}},[_c('span',{staticClass:"apply-pass",on:{"click":function($event){return _vm.handlePass(
                                                        scope.row[
                                                            'applyUserId'
                                                        ],
                                                    )}}},[_vm._v(" 通过 ")]),_c('span',{staticClass:"apply-refuse",on:{"click":function($event){return _vm.handleRefuse(
                                                        scope.row[
                                                            'applyUserId'
                                                        ],
                                                    )}}},[_vm._v(" 驳回 ")])])]):_c('span',[_vm._v(" "+_vm._s(scope.row[item.prop])+" ")])]}}],null,true)})]}),_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('el-empty',{attrs:{"image-size":200}})],1)],2)],1)]),_c('div',{staticClass:"pagination_div"},[_c('el-pagination',{attrs:{"background":"","hide-on-single-page":_vm.pageTotal < 10,"layout":"sizes,prev, pager, next","total":_vm.pageTotal,"page-sizes":[10, 20, 30, 40, 50],"page-size":_vm.pageSize,"current-page":_vm.pagePosition},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.pagePosition=$event},"update:current-page":function($event){_vm.pagePosition=$event}}})],1)])]),(_vm.tureDialogVisible)?_c('turnManage-dialog',{ref:"turnManageDialog",on:{"isTurnOver":_vm.init}}):_vm._e(),(_vm.watchDialogVisible)?_c('colleagueBox-dialog',{ref:"colleagueBoxDialog"}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }